import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

interface Product {
  id: number;
  name: string;
  quantity: number;
  description: string;
  username: string;
  price: number;
  redeemed_at?: string;
}

const InventoryPage: React.FC = () => {
  const [inventory, setInventory] = useState<Product[]>([]);
  const [redeemedItems, setRedeemedItems] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<'inventory' | 'redeemed'>('inventory');
  const auth = useAuth();

  const fetchInventory = useCallback(async () => {
    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;

      const response = await axios.get(`${apiBaseUrl}/api/inventory`, {
        params: { username: auth.username },
      });
      setInventory(response.data.inventory || []);
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setInventory([]);
    } finally {
      setLoading(false);
    }
  }, [auth.username]);

  const fetchRedeemedItems = useCallback(async () => {
    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;

      const response = await axios.get(`${apiBaseUrl}/api/redeemed`, {
        params: { username: auth.username },
      });
      setRedeemedItems(response.data.redeemedItems || []);
    } catch (error) {
      console.error('Error fetching redeemed items:', error);
      setRedeemedItems([]);
    }
  }, [auth.username]);

  useEffect(() => {
    fetchInventory();
    fetchRedeemedItems();
  }, [fetchInventory, fetchRedeemedItems]);

  const handleRedeem = async (itemId: number) => {
    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;

      await axios.post(`${apiBaseUrl}/api/redeem`, { itemId }, {
        params: { username: auth.username },
      });

      setInventory(prevInventory =>
        prevInventory.map(item =>
          item.id === itemId ? { ...item, quantity: item.quantity - 1 } : item
        ).filter(item => item.quantity > 0)
      );

      fetchRedeemedItems();
    } catch (error) {
      console.error('Error redeeming item:', error);
    }
  };

  const handleConfirmRedeem = async (itemId: number) => {
    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;
  
      await axios.post(`${apiBaseUrl}/api/confirm-redeem`, { itemId }, {
        params: { username: auth.username },
      });
  
      alert('Redemption confirmed successfully.');
    } catch (error) {
      console.error('Error confirming redemption:', error);
      alert('Failed to confirm redemption.');
    }
  };  

  if (loading) {
    return <p>Loading your inventory...</p>;
  }

  return (
    <div className="flex flex-col items-center bg-stone-900 p-4 min-h-screen text-zinc-100">
      <h1 className="mb-6 font-bold text-4xl">Your Inventory</h1>
      <div className="mb-4">
        <button
          className={`mr-4 ${activeTab === 'inventory' ? 'text-yellow-500' : ''}`}
          onClick={() => setActiveTab('inventory')}
        >
          Inventory
        </button>
        <button
          className={`${activeTab === 'redeemed' ? 'text-yellow-500' : ''}`}
          onClick={() => setActiveTab('redeemed')}
        >
          Redeemed
        </button>
      </div>
      {activeTab === 'inventory' && inventory.length > 0 ? (
        <ul className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {inventory.map((item) => (
            <li key={item.id} className="bg-gray-800 p-4 rounded">
              <h3 className="font-semibold text-xl">{item.name}</h3>
              <p className="mt-1 text-gray-400">{item.description}</p>
              <p className="mt-2">Quantity Purchased: {item.quantity}</p>
              <p className="mt-1 text-yellow-500">Price Paid: {item.price} Spanks</p>
              <p className="mt-1 text-red-400">Seller: {item.username}</p>
              <button
                className="bg-green-600 hover:bg-green-700 mt-2 px-4 py-2 rounded text-white"
                onClick={() => handleRedeem(item.id)}
                disabled={item.quantity <= 0}
              >
                Redeem
              </button>
            </li>
          ))}
        </ul>
      ) : activeTab === 'redeemed' && redeemedItems.length > 0 ? (
        <ul className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {redeemedItems.map((item) => (
            <li key={item.id} className="bg-gray-800 p-4 rounded">
              <h3 className="font-semibold text-xl">{item.name}</h3>
              <p className="mt-1 text-gray-400">{item.description}</p>
              {item.redeemed_at && (
                <p className="mt-2">Redeemed At: {new Date(item.redeemed_at).toLocaleString()}</p>
              )}
              <p className="mt-1 text-red-400">Seller: {item.username}</p>
              <button
                className="bg-blue-600 hover:bg-blue-700 mt-2 px-4 py-2 rounded text-white"
                onClick={() => handleConfirmRedeem(item.id)}
              >
                Confirm Redeem
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>{activeTab === 'inventory' ? 'You have not purchased any products yet.' : 'You have not redeemed any products yet.'}</p>
      )}
    </div>
  );
};

export default InventoryPage;
