import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="w-full p-6 bg-zinc-900 text-zinc-100 text-md shadow-lg indie-flower-regular">
      <div className="container mx-auto flex justify-end">
        <p>“Every love story is beautiful, but ours is my favorite. -Nunya XO”</p>
      </div>
    </footer>
  );
};

export default Footer;
