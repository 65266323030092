import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  isLoggedIn: boolean;
  username: string | null;
  login: (username: string, password: string) => boolean;
  logout: () => void;
  verifyAuth: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    verifyAuth();
  }, []);

  const verifyAuth = () => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    } else {
      setIsLoggedIn(false);
      setUsername(null);
    }
  };

  const login = (username: string, password: string): boolean => {
    console.log('Attempting login for user:', username);

    if (!process.env.REACT_APP_DOOMSLAYER_USERNAME || !process.env.REACT_APP_DOOMSLAYER_PASSWORD || !process.env.REACT_APP_NUNYA_USERNAME || !process.env.REACT_APP_NUNYA_PASSWORD) {
      console.error('One or more environment variables are missing');
      return false;
    }

    const users = [
      { username: process.env.REACT_APP_DOOMSLAYER_USERNAME, password: process.env.REACT_APP_DOOMSLAYER_PASSWORD },
      { username: process.env.REACT_APP_NUNYA_USERNAME, password: process.env.REACT_APP_NUNYA_PASSWORD }
    ];

    const user = users.find(user => user.username === username && user.password === password);

    if (user) {
      setIsLoggedIn(true);
      setUsername(username);
      localStorage.setItem('username', username);
      navigate('/dashboard'); 
      return true;
    }

    console.error('Invalid username or password');
    return false;
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUsername(null);
    localStorage.removeItem('username');
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, username, login, logout, verifyAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
