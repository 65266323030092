import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  IconButton,
  HStack,
  Input,
  Text,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { X } from 'lucide-react';

interface AddEditProductModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  editProductId: number | null;
  productName: string;
  setProductName: (name: string) => void;
  productQuantity: number;
  setProductQuantity: (quantity: number) => void;
  productDescription: string;
  setProductDescription: (description: string) => void;
  productPrice: number;
  setProductPrice: (price: number) => void;
}

const AddEditProductModal: React.FC<AddEditProductModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  editProductId,
  productName,
  setProductName,
  productQuantity,
  setProductQuantity,
  productDescription,
  setProductDescription,
  productPrice,
  setProductPrice,
}) => {
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const numericValue = value === '' ? 0 : Math.min(9999, Math.max(0, Math.floor(Number(value))));
    if (!isNaN(numericValue)) {
      setProductPrice(numericValue);
    }
  };

  const handleQuantityChange = (newQuantity: number): void => {
    if (newQuantity >= 1 && newQuantity <= 10) {
      setProductQuantity(newQuantity);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} isCentered size={{ base: 'md', md: 'lg', lg: 'xl' }}>
      <ModalOverlay />
      <ModalContent bgColor="#121212" color="#e0e0e0" p={{ base: 4, md: 6 }}>
        <ModalHeader color="#ff5252" display="flex" justifyContent="space-between" alignItems="center">
          {editProductId ? 'Edit Product' : 'Add New Product'}
          <IconButton
            icon={<X />}
            onClick={onRequestClose}
            aria-label="Close modal"
            backgroundColor="transparent"
            color="#A0AEC0"
            _hover={{ color: '#ff5252' }}
          />
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Product Name</FormLabel>
              <Textarea
                value={productName}
                onChange={(e) => setProductName(e.target.value.slice(0, 60))}
                focusBorderColor="#ff5252"
                bg="#1b1b1b"
                maxLength={60}
                resize="none"
                overflow="hidden"
                minH="60px"
                lineHeight="1.5"
              />
              <Text fontSize="sm" color="#b0bec5" textAlign="right">
                {productName.length}/60
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Product Description</FormLabel>
              <Textarea
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value.slice(0, 300))}
                focusBorderColor="#ff5252"
                bg="#1b1b1b"
                maxLength={300}
                h="180px"
              />
              <Text fontSize="sm" color="#b0bec5" textAlign="right">
                {productDescription.length}/300
              </Text>
            </FormControl>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl>
                <FormLabel>Price (Spanks)</FormLabel>
                <Input
                  type="text"
                  value={productPrice === 0 ? '' : productPrice}
                  onChange={handlePriceChange}
                  focusBorderColor="#ff5252"
                  bg="#1b1b1b"
                  pattern="[0-9]*"
                  maxLength={4}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Quantity (1-10)</FormLabel>
                <HStack>
                  <IconButton
                    icon={<MinusIcon />}
                    onClick={() => handleQuantityChange(productQuantity - 1)}
                    isDisabled={productQuantity <= 1}
                    colorScheme="red"
                    aria-label="Decrease quantity"
                  />
                  <Input
                    value={productQuantity}
                    readOnly
                    bg="#1b1b1b"
                    textAlign="center"
                  />
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => handleQuantityChange(productQuantity + 1)}
                    isDisabled={productQuantity >= 10}
                    colorScheme="red"
                    aria-label="Increase quantity"
                  />
                </HStack>
              </FormControl>
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onRequestClose}
            size="md"
            variant="ghost"
            color="#A0AEC0"
            _hover={{ color: '#ffffff' }}
            mr={3}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            size="md"
            variant="solid"
            colorScheme="red"
          >
            {editProductId ? 'Update Product' : 'Add Product'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditProductModal;
