import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import AppRouter from './AppRouter';
import Footer from './components/Footer';
import { AuthProvider } from './contexts/AuthContext';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen bg-stone-900">
          <Header />
          <div className="flex-grow">
            <AppRouter />
          </div>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;
