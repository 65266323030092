import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!auth.isLoggedIn) {
      navigate('/signin');
    }
  }, [auth.isLoggedIn, navigate]);

  if (!auth.isLoggedIn) {
    return null;
  }

  return (
    <div className="flex justify-center items-center bg-gray-900 p-4 min-h-screen">
      <div className="bg-gray-800 shadow-lg p-8 rounded-lg w-full max-w-md text-center">
        <h1 className="mb-6 font-bold text-2xl text-gray-100">Protected Page</h1>
        <p className="text-gray-400">This is a protected page. You must be logged in to view this content.</p>
      </div>
    </div>
  );
};

export default ProtectedPage;
