import React, { useEffect, useState } from 'react';
import { Trash2, Square, CheckSquare, ChevronLeft, ChevronRight } from 'lucide-react';

interface Memory {
  id: number;
  message: string;
  username: string;
  created_at: string;
}

const FullMemoryBoard: React.FC = () => {
  const [memories, setMemories] = useState<Memory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMemories, setSelectedMemories] = useState<number[]>([]);
  const [deletionMode, setDeletionMode] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const memoriesPerPage = 5;

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_BASE_URL_PROD + '/api/memories'
      : process.env.REACT_APP_API_BASE_URL_DEV + '/api/memories';

    fetch(apiUrl, { cache: 'no-store' })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setMemories(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching memories:', error);
        setError('Failed to fetch memories. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handleSelectMemory = (id: number) => {
    setSelectedMemories(prev =>
      prev.includes(id) ? prev.filter(memId => memId !== id) : [...prev, id]
    );
  };

  const handleDeleteMemories = () => {
    const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_BASE_URL_PROD + '/api/memories/delete'
      : process.env.REACT_APP_API_BASE_URL_DEV + '/api/memories/delete';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: selectedMemories })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete memories');
        }
        return response.json();
      })
      .then(data => {
        setMemories(data);
        setSelectedMemories([]);
        setDeletionMode(false); // Exit deletion mode after deletion
      })
      .catch(error => {
        console.error('Error deleting memories:', error);
        setError('Failed to delete memories. Please try again later.');
      });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const indexOfLastMemory = currentPage * memoriesPerPage;
  const indexOfFirstMemory = indexOfLastMemory - memoriesPerPage;
  const currentMemories = memories.slice(indexOfFirstMemory, indexOfLastMemory);

  const totalPages = Math.ceil(memories.length / memoriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3;
    const halfMaxPageButtons = Math.floor(maxPageButtons / 2);

    let startPage = Math.max(1, currentPage - halfMaxPageButtons);
    let endPage = Math.min(totalPages, currentPage + halfMaxPageButtons);

    if (currentPage <= halfMaxPageButtons) {
      endPage = Math.min(totalPages, maxPageButtons);
    }

    if (currentPage + halfMaxPageButtons > totalPages) {
      startPage = Math.max(1, totalPages - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-2 py-1 mx-1 rounded-full transition-all duration-300 ${i === currentPage ? 'bg-red-500 text-white' : 'bg-zinc-700 text-gray-200 hover:bg-red-400 hover:text-white'}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <span key="startEllipsis" className="px-2 py-1 text-gray-200">...</span>
      );
      pageNumbers.unshift(
        <button
          key={1}
          className="bg-zinc-700 hover:bg-red-400 mx-1 px-2 py-1 rounded-full text-gray-200 hover:text-white transition-all duration-300"
          onClick={() => handlePageChange(1)}
        >
          1
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="endEllipsis" className="px-2 py-1 text-gray-200">...</span>
      );
      pageNumbers.push(
        <button
          key={totalPages}
          className="bg-zinc-700 hover:bg-red-400 mx-1 px-2 py-1 rounded-full text-gray-200 hover:text-white transition-all duration-300"
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="bg-cover bg-center bg-fixed min-h-screen">
      <div className="flex flex-col justify-center items-center bg-black p-4 w-full min-h-screen">
        <div className="border-zinc-700 bg-zinc-900 bg-opacity-80 shadow-xl p-6 border rounded-lg w-full max-w-5xl">
          <div className="flex md:flex-row flex-col justify-between items-center mb-8">
            <h2 className="font-semibold text-2xl text-center text-red-500 md:text-4xl md:text-left indie-flower-regular">Love in Every Memory</h2>
            <div className="flex space-x-2 mt-4 md:mt-0">
              {deletionMode ? (
                <button
                  className="flex items-center bg-red-500 px-4 py-2 rounded text-white"
                  onClick={() => {
                    setDeletionMode(false);
                    setSelectedMemories([]);
                  }}
                >
                  <Trash2 className="mr-2 text-white" /> Cancel
                </button>
              ) : (
                <button
                  className="flex items-center text-white"
                  onClick={() => setDeletionMode(true)}
                >
                  <Trash2 className="mr-2 text-white" />
                </button>
              )}
            </div>
          </div>
          {loading ? (
            <p className="text-center text-zinc-400">Loading...</p>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : memories.length > 0 ? (
            <div>
              <ul className="space-y-6">
                {currentMemories.map(memory => (
                  <li key={memory.id} className="flex md:flex-row flex-col items-center bg-gradient-to-r from-zinc-800 via-zinc-700 to-zinc-600 shadow-lg p-4 md:p-6 rounded-lg">
                    {deletionMode && (
                      <button
                        className="md:mr-4 mb-4 md:mb-0"
                        onClick={() => handleSelectMemory(memory.id)}
                      >
                        {selectedMemories.includes(memory.id) ? (
                          <CheckSquare className="text-red-500" />
                        ) : (
                          <Square className="text-zinc-500" />
                        )}
                      </button>
                    )}
                    <div className="flex-grow text-center md:text-left">
                      <p className="mb-2 text-md text-zinc-100 md:text-lg italic">"{memory.message}"</p>
                      <p className="mb-1 text-red-400">by {memory.username}</p>
                      <p className="text-zinc-500">{new Date(memory.created_at).toLocaleString()}</p>
                    </div>
                  </li>
                ))}
              </ul>
              {deletionMode && (
                <div className="flex justify-end mt-8">
                  <button
                    className="flex items-center bg-red-500 px-4 py-2 rounded text-white"
                    onClick={handleDeleteMemories}
                    disabled={selectedMemories.length === 0}
                  >
                    <Trash2 className="mr-2" /> Delete Selected Memories
                  </button>
                </div>
              )}
              <div className="flex justify-center mt-8">
                <div className="flex items-center space-x-2">
                  <button
                    className="bg-zinc-700 p-2 rounded-full text-gray-200 hover:text-white transition-all duration-300"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <ChevronLeft size={20} />
                  </button>
                  {renderPageNumbers()}
                  <button
                    className="bg-zinc-700 hover:bg-red-400 p-2 rounded-full text-gray-200 hover:text-white transition-all duration-300"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-center text-zinc-500">No memories yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullMemoryBoard;
