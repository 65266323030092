import React, { useEffect, useState } from 'react';

interface Response {
  response: string;
  username: string;
  created_at: string;
}

interface LatestQuestionData {
  question: string;
  responses: Response[];
}

const LatestQuestion: React.FC = () => {
  const [data, setData] = useState<LatestQuestionData>({ question: '', responses: [] });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLatestQuestion = async () => {
      try {
        const apiUrl =
          process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_API_BASE_URL_PROD}/api/latestQuestion`
            : `${process.env.REACT_APP_API_BASE_URL_DEV}/api/latestQuestion`;

        const response = await fetch(apiUrl, { cache: 'no-store' });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: LatestQuestionData = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching latest question:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestQuestion();
  }, []);

  const renderResponses = (username: string) => {
    const userResponses = data.responses.filter(
      (res) => res.username.toLowerCase() === username.toLowerCase()
    );
    return userResponses.length > 0 ? (
      userResponses.map((res, index) => (
        <div key={index} className="bg-zinc-800 shadow-sm mb-4 p-4 rounded-lg">
          <p className="mb-2 text-zinc-200 break-words">"{res.response}"</p>
          <div className="flex justify-between items-center border-zinc-700 mt-3 pt-2 border-t text-sm text-zinc-400">
            <span className="font-medium break-words">- {res.username}</span>
            <span className="text-xs italic">
              {res.created_at ? new Date(res.created_at).toLocaleString() : 'No date available'}
            </span>
          </div>
        </div>
      ))
    ) : (
      <p className="text-zinc-400 italic">{username} has not submitted a response yet!</p>
    );
  };

  return (
    <div className="bg-zinc-950 shadow-md mx-auto mt-1 p-6 rounded-lg w-full">
      <h2 className="mb-6 font-bold text-2xl text-center text-red-500">
        Q & A
      </h2>
      {loading ? (
        <p className="text-center text-zinc-500">Loading...</p>
      ) : (
        <div>
          <h3 className="mb-3 text-xl text-zinc-300">Question:</h3>
          <p className="mb-8 text-lg text-red-400 break-words">{data.question}</p>
          <h3 className="mb-4 text-xl text-zinc-300">Answers:</h3>
          <div className="gap-6 grid grid-cols-1 md:grid-cols-2">
            <div className="bg-zinc-700 shadow-sm p-4 rounded-lg">
              <h4 className="mb-3 font-semibold text-lg text-zinc-300">Nunya:</h4>
              {renderResponses('thenunya')}
            </div>
            <div className="bg-zinc-700 shadow-sm p-4 rounded-lg">
              <h4 className="mb-3 font-semibold text-lg text-zinc-300">Mister Doom:</h4>
              {renderResponses('slayer4229')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LatestQuestion;
