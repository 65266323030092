import React, { useEffect, useState } from 'react';

interface TimeData {
  city: string;
  time: string;
}

const CurrentTime: React.FC = () => {
  const [times, setTimes] = useState<TimeData[]>([]);

  const updateTime = () => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const indiaTime = new Date().toLocaleString('en-US', {
      ...options,
      timeZone: 'Asia/Kolkata',
    });

    const floridaTime = new Date().toLocaleString('en-US', {
      ...options,
      timeZone: 'America/New_York',
    });

    setTimes([
      { city: 'India', time: indiaTime },
      { city: 'Florida', time: floridaTime },
    ]);
  };

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-zinc-950 shadow-md mb-1 p-4 rounded-lg">
      <div className="flex md:flex-row flex-col justify-around">
        {times.map((timeData, index) => (
          <div
            key={index}
            className="bg-zinc-700 bg-opacity-50 shadow-lg backdrop-blur-lg backdrop-filter mb-4 md:mb-0 p-6 rounded-lg text-center"
          >
            <h3 className="mb-2 font-semibold text-lg text-zinc-200">{timeData.city}</h3>
            <p className="font-mono text-zinc-300">{timeData.time}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrentTime;
