import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AnchorTemporaryDrawer from '../components/ui/AnchorTemporaryDrawer';

const Header: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (auth.isLoggedIn) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <header className="bg-zinc-900 shadow-lg p-4 text-zinc-100">
      <div className="flex justify-between items-center mx-auto container">
        <div className="font-bold text-lg cursor-pointer" onClick={handleLogoClick}>
          ForDoom
        </div>
        {auth.isLoggedIn ? (
          <div className="flex items-center space-x-2">
            <p className="text-zinc-400">Welcome, {auth.username}!</p>
            <AnchorTemporaryDrawer />
          </div>
        ) : (
          <nav className="space-x-4">
            <Link to="/signin" className="hover:text-red-400 transition duration-300">Sign In</Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
