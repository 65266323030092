import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Memory {
  id: number;
  message: string;
  username: string;
  created_at: string;
}

const MemoryBoard: React.FC = () => {
  const [memory, setMemory] = useState<Memory | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_BASE_URL_PROD + '/api/memory'
      : process.env.REACT_APP_API_BASE_URL_DEV + '/api/memory';

    fetch(apiUrl, { cache: 'no-store' })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setMemory(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching memory:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex-grow bg-zinc-800 shadow-md mb-3 md:mb-0 p-4 rounded-lg">
      <h2 className="font-semibold text-lg text-red-400">Memory Board</h2>
      {loading ? (
        <p className="text-center text-zinc-400">Loading...</p>
      ) : memory ? (
        <div className="bg-zinc-700 shadow-md mt-2 p-4 rounded-lg">
          <p className="mb-2 text-zinc-300">"{memory.message}"</p>
          <p className="mb-2 text-zinc-400">by {memory.username}</p>
          <p className="mb-4 text-zinc-500">{new Date(memory.created_at).toLocaleString()}</p>
          <Link to="/memories" className="text-red-500 hover:text-red-400 hover:underline">View Full Memory Board</Link>
        </div>
      ) : (
        <p className="text-center text-zinc-400">Create some memories on Discord to get started!</p>
      )}
    </div>
  );
};

export default MemoryBoard;
