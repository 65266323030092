import React from 'react';
import { Link } from 'react-router-dom';
import { Heart } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import Dashboard from './Dashboard';

const HomePage: React.FC = () => {
  const auth = useAuth();

  if (auth.isLoggedIn) {
    return <Dashboard />;
  }

  return (
    <div className="flex justify-center items-center bg-stone-900 p-4 min-h-screen">
      <div className="bg-stone-800 shadow-lg p-8 rounded-lg w-full max-w-3xl text-center">
        <img
          src="/images/Homepage/nunyaxdoom.webp"
          alt="Cute couple"
          className="border-4 border-stone-700 mx-auto mb-4 rounded-lg w-48 md:w-64 lg:w-80 h-48 md:h-64 lg:h-80"
        />
        <h1 className="mb-4 font-bold text-3xl text-rose-200 md:text-4xl">Welcome!</h1>
        <p className="mb-6 text-stone-400 md:text-lg">
          I'm so happy you're here! This site is made with all my love for my man. It is to be a fun space for us to share, and for me to spoil him rotten.
        </p>
        <p className="mb-8 text-stone-400 md:text-lg">
          Please <Link to="/signin" className="text-rose-500 hover:underline">sign in</Link> to access all the amazing features I have in store.
        </p>
        <Heart className="mx-auto text-rose-200" size={32} strokeWidth={1.5} />
      </div>
    </div>
  );
};

export default HomePage;
