import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sparkles } from 'lucide-react';
import ConfirmRemoveModal from '../modals/ConfirmRemoveModal';
import AddEditProductModal from '../modals/AddEditProductModal';

interface Product {
  id: number;
  name: string;
  quantity: number;
  description: string;
  username: string;
  price: number;
}

const NunyazonPage: React.FC = () => {
  const [spankTotal, setSpankTotal] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [activeTab, setActiveTab] = useState<'shop' | 'manage'>('shop');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [newProductName, setNewProductName] = useState('');
  const [newProductQuantity, setNewProductQuantity] = useState<number>(1);
  const [newProductDescription, setNewProductDescription] = useState('');
  const [newProductPrice, setNewProductPrice] = useState<number>(0);
  const [editProductId, setEditProductId] = useState<number | null>(null);
  const [productToRemove, setProductToRemove] = useState<Product | null>(null);
  const auth = useAuth();

  useEffect(() => {
    const fetchSpankTotalAndProducts = async () => {
      let discordId: string | undefined;

      if (auth.username === 'Nunya') {
        discordId = process.env.REACT_APP_NUNYA_DISCORD_ID;
      } else if (auth.username === 'DoomSlayer') {
        discordId = process.env.REACT_APP_DOOMSLAYER_DISCORD_ID;
      } else {
        console.error('Unknown username');
        setLoading(false);
        return;
      }

      try {
        const apiBaseUrl =
          process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_API_BASE_URL_PROD
            : process.env.REACT_APP_API_BASE_URL_DEV;

        if (!apiBaseUrl) {
          throw new Error('API base URL is not defined in environment variables.');
        }

        const spankResponse = await axios.get(`${apiBaseUrl}/api/spankTotal`, {
          params: { discordId },
        });
        setSpankTotal(spankResponse.data.spankTotal);

        const productsResponse = await axios.get(`${apiBaseUrl}/api/products`);
        setProducts(Array.isArray(productsResponse.data.products) ? productsResponse.data.products : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSpankTotal(null);
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSpankTotalAndProducts();
  }, [auth.username]);

  const purchaseProduct = async (productId: number) => {
    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;
  
      const response = await axios.post(`${apiBaseUrl}/api/purchase`, { productId, quantity: 1 }, {
        params: { username: auth.username },
      });
  
      if (response.data.success) {
        // Update spank total
        setSpankTotal(response.data.newSpankTotal);
  
        // Re-fetch the product list after purchase to ensure it reflects the current state
        const productsResponse = await axios.get(`${apiBaseUrl}/api/products`);
        setProducts(productsResponse.data.products);
  
        toast.success('Product purchased successfully!');
      } else {
        toast.error(response.data.message || 'Failed to purchase product.');
      }
    } catch (error) {
      console.error('Error purchasing product:', error);
      toast.error('Failed to purchase product. Please try again.');
    }
  };  

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    cancelEditing();
  };

  const openConfirmModal = (product: Product) => {
    setProductToRemove(product);
    setConfirmModalIsOpen(true);
  };

  const confirmRemoveProduct = async () => {
    if (!productToRemove) return;

    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;

      if (!apiBaseUrl) {
        throw new Error('API base URL is not defined in environment variables.');
      }

      await axios.delete(`${apiBaseUrl}/api/products/${productToRemove.id}`, {
        params: { username: auth.username },
      });

      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productToRemove.id));
      toast.success('Product removed successfully!');
    } catch (error) {
      console.error('Error removing product:', error);
      toast.error('Failed to remove product. Please try again.');
    } finally {
      setConfirmModalIsOpen(false);
      setProductToRemove(null);
    }
  };

  const addOrUpdateProduct = async () => {
    if (
      newProductName.trim() === '' ||
      newProductDescription.trim() === '' ||
      newProductQuantity < 1 ||
      newProductQuantity > 10 ||
      newProductPrice < 0
    ) {
      toast.error('Please enter a valid product name, description, quantity (1-10), and price.');
      return;
    }

    try {
      const apiBaseUrl =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_BASE_URL_PROD
          : process.env.REACT_APP_API_BASE_URL_DEV;

      if (!apiBaseUrl) {
        throw new Error('API base URL is not defined in environment variables.');
      }

      if (editProductId) {
        await axios.put(`${apiBaseUrl}/api/products/${editProductId}`, {
          name: newProductName.trim(),
          quantity: newProductQuantity,
          description: newProductDescription.trim(),
          username: auth.username,
          price: newProductPrice,
        });

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === editProductId
              ? { ...product, name: newProductName, quantity: newProductQuantity, description: newProductDescription, price: newProductPrice }
              : product
          )
        );

        toast.success('Product updated successfully!');
        setEditProductId(null);
      } else {
        const response = await axios.post(`${apiBaseUrl}/api/products`, {
          name: newProductName.trim(),
          quantity: newProductQuantity,
          description: newProductDescription.trim(),
          username: auth.username,
          price: newProductPrice,
        });

        if (response.data && response.data.product) {
          setProducts((prevProducts) => [...prevProducts, response.data.product]);
          toast.success('Product added successfully!');
        }
      }

      closeModal();
    } catch (error) {
      console.error('Error adding or updating product:', error);
      toast.error('Failed to add or update product. Please try again.');
    }
  };

  const startEditingProduct = (product: Product) => {
    setEditProductId(product.id);
    setNewProductName(product.name);
    setNewProductQuantity(product.quantity);
    setNewProductDescription(product.description);
    setNewProductPrice(product.price);
    openModal();
  };

  const cancelEditing = () => {
    setEditProductId(null);
    setNewProductName('');
    setNewProductQuantity(1);
    setNewProductDescription('');
    setNewProductPrice(0);
  };

  const userProducts = products.filter((product) => product.username === auth.username);

  return (
    <div className="flex flex-col items-center bg-stone-900 p-4 min-h-screen text-zinc-100">
      <ToastContainer />
      <div className="flex md:flex-row flex-col justify-between items-center mt-6 mb-4 w-full max-w-6xl">
        <div className="flex items-center space-x-2 mb-4 md:mb-0">
          <h1 className="font-bold text-4xl">Nunyazon</h1>
          <Sparkles className="w-8 h-8 text-yellow-500" />
        </div>
        {loading ? (
          <p className="text-gray-400 text-lg">Loading your spank total...</p>
        ) : (
          <p className="text-center text-gray-400 text-lg md:text-left">
            {spankTotal !== null
              ? `You have ${spankTotal} spanks`
              : 'Could not retrieve your spank total'}
          </p>
        )}
      </div>

      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab('shop')}
          className={`mr-4 px-4 py-2 rounded ${activeTab === 'shop' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-gray-300'
            }`}
        >
          Shop
        </button>
        <button
          onClick={() => setActiveTab('manage')}
          className={`px-4 py-2 rounded ${activeTab === 'manage' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-gray-300'
            }`}
        >
          Manage Products
        </button>
      </div>

      {activeTab === 'shop' && (
        <div className="w-full">
          <h2 className="mb-4 text-3xl">Available Products</h2>
          {products.length > 0 ? (
            <ul className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {products.map((product) => (
                <li key={product.id} className="bg-gray-800 p-4 rounded">
                  <h3 className="font-semibold text-xl">{product.name}</h3>
                  <p className="mt-1 text-gray-400">{product.description}</p>
                  <p className="mt-2">Quantity Available: {product.quantity}</p>
                  <p className="mt-1 text-yellow-500">Price: {product.price} Spanks</p>
                  <p className="mt-1 text-red-400">Posted by: {product.username}</p>
                  <button
                    onClick={() => purchaseProduct(product.id)}
                    className="bg-green-500 hover:bg-green-600 mt-4 px-4 py-2 rounded text-black"
                  >
                    Buy Now
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No products available.</p>
          )}
        </div>
      )}

      {activeTab === 'manage' && (
        <div className="w-full">
          <h2 className="mb-4 text-3xl">Manage Your Products</h2>
          <button
            onClick={openModal}
            className="bg-yellow-500 hover:bg-yellow-600 mb-4 px-4 py-2 rounded text-black"
          >
            Add New Product
          </button>
          {userProducts.length > 0 ? (
            <ul className="space-y-4">
              {userProducts.map((product) => (
                <li key={product.id} className="flex justify-between items-center bg-gray-800 p-4 rounded">
                  <div>
                    <h3 className="font-semibold text-xl">{product.name}</h3>
                    <p className="mt-2">Quantity Available: {product.quantity}</p>
                    <p className="mt-1 text-gray-400">{product.description}</p>
                    <p className="mt-1 text-yellow-500">Price: {product.price} Spanks</p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => startEditingProduct(product)}
                      className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded text-black"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => openConfirmModal(product)}
                      className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No products to manage.</p>
          )}
        </div>
      )}

      <AddEditProductModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSubmit={addOrUpdateProduct}
        editProductId={editProductId}
        productName={newProductName}
        setProductName={setNewProductName}
        productQuantity={newProductQuantity}
        setProductQuantity={setNewProductQuantity}
        productDescription={newProductDescription}
        setProductDescription={setNewProductDescription}
        productPrice={newProductPrice}
        setProductPrice={setNewProductPrice}
      />

      <ConfirmRemoveModal
        isOpen={confirmModalIsOpen}
        onRequestClose={() => setConfirmModalIsOpen(false)}
        onConfirm={confirmRemoveProduct}
        productName={productToRemove?.name}
      />
    </div>
  );
};

export default NunyazonPage;
