import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DailyQuote: React.FC = () => {
  const [quote, setQuote] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_BASE_URL_PROD + '/api/quoteOfTheDay'
      : process.env.REACT_APP_API_BASE_URL_DEV + '/api/quoteOfTheDay';

    axios.get(apiUrl)
      .then(response => {
        setQuote(response.data.quote);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching quote of the day:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col justify-start bg-zinc-800 shadow-md mb-3 md:mb-0 p-6 rounded-lg w-full h-full">
      <h2 className="-mt-2 mb-4 -ml-2 font-semibold text-lg text-red-400">Daily Quote</h2>
      <div className="flex flex-grow justify-center items-center">
        {loading ? (
          <p className="text-center text-zinc-400">Loading...</p>
        ) : (
          <p className="max-w-full text-center text-lg text-zinc-300 sm:text-xl italic leading-relaxed">
            "{quote}"
          </p>
        )}
      </div>
    </div>
  );
};

export default DailyQuote;
