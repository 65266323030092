import React from 'react';
import Modal from 'react-modal';

interface ConfirmRemoveModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  productName?: string;
}

const ConfirmRemoveModal: React.FC<ConfirmRemoveModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  productName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirm Product Removal"
      className="bg-gray-800 mx-auto p-6 rounded max-w-lg text-white modal"
      overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
    >
      <h2 className="mb-4 text-2xl">Confirm Removal</h2>
      <p>Are you sure you want to remove the product "{productName}"?</p>
      <div className="flex justify-end space-x-4 mt-4">
        <button
          onClick={onConfirm}
          className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
        >
          Yes, Remove
        </button>
        <button
          onClick={onRequestClose}
          className="bg-gray-500 hover:bg-gray-600 px-4 py-2 rounded text-white"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmRemoveModal;
