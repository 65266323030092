import React, { useState } from 'react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  VStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { Home, BookOpen, LogOut, MoreVertical, DollarSign, Box as BoxIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function AnchorTemporaryDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    toggleDrawer(); 
  };

  const handleLogout = () => {
    auth.logout();
    navigate('/');
    toggleDrawer(); 
  };

  return (
    <div>
      <IconButton
        icon={<MoreVertical />}
        onClick={toggleDrawer}
        aria-label="Open menu"
        backgroundColor="transparent"
        color="#A0AEC0" // Light color by default
        _hover={{ color: '#ffffff' }} // White color on hover
        _active={{ backgroundColor: 'transparent' }}
        _focus={{ boxShadow: 'none' }}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={toggleDrawer}>
        <DrawerOverlay />
        <DrawerContent bg="#1c1c1e" color="#ffffff">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px" borderColor="#3a3a3a">
            Menu
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={6} align="stretch" mt={4}>
              <Box
                onClick={() => handleNavigate('/dashboard')}
                cursor="pointer"
                display="flex"
                alignItems="center"
                px={3}
                py={2}
                _hover={{ bg: '#2c2c2e', borderRadius: 'md' }}
              >
                <Home style={{ color: '#ffffff', marginRight: '12px' }} />
                <Text fontSize="lg">Dashboard</Text>
              </Box>
              <Box
                onClick={() => handleNavigate('/memories')}
                cursor="pointer"
                display="flex"
                alignItems="center"
                px={3}
                py={2}
                _hover={{ bg: '#2c2c2e', borderRadius: 'md' }}
              >
                <BookOpen style={{ color: '#ffffff', marginRight: '12px' }} />
                <Text fontSize="lg">Memory Board</Text>
              </Box>
              <Box
                onClick={() => handleNavigate('/spank-bank')}
                cursor="pointer"
                display="flex"
                alignItems="center"
                px={3}
                py={2}
                _hover={{ bg: '#2c2c2e', borderRadius: 'md' }}
              >
                <DollarSign style={{ color: '#ffffff', marginRight: '12px' }} />
                <Text fontSize="lg">Nunyazon</Text>
              </Box>
              <Box
                onClick={() => handleNavigate('/inventory')}
                cursor="pointer"
                display="flex"
                alignItems="center"
                px={3}
                py={2}
                _hover={{ bg: '#2c2c2e', borderRadius: 'md' }}
              >
                <BoxIcon style={{ color: '#ffffff', marginRight: '12px' }} />
                <Text fontSize="lg">Inventory</Text>
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px" borderColor="#3a3a3a" mt={6}>
            <Box
              onClick={handleLogout}
              cursor="pointer"
              display="flex"
              alignItems="center"
              w="full"
              px={3}
              py={2}
              _hover={{ bg: '#2c2c2e', borderRadius: 'md' }}
            >
              <LogOut style={{ color: '#ffffff', marginRight: '12px' }} />
              <Text fontSize="lg">Logout</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
